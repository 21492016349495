<template>
  <div class="EmpresasHome">
    <EmpresasHomeNoLogin v-if="!sesion" />
    <EmpresasHomeSeleccionar v-else />
    <div
      class="technical-drawing__section empresas-login"
      v-if="!sesion"
    />
  </div>
</template>
<script>
import EmpresasHomeNoLogin from "@/components/empresas/EmpresasHomeNoLogin.vue";
import EmpresasHomeSeleccionar from "@/components/empresas/EmpresasHomeSeleccionar.vue";
import standard from "@/mixins/standard.js";
export default {
  name: "EmpresasHome",
  mixins: [standard],
  components: {
    EmpresasHomeNoLogin,
    EmpresasHomeSeleccionar,
  },
  data() {
    return {
      idTest: null,
      sesion: false,
      servicios: [
        {
          title: "Facturas",
          description:
            "Para poder solicitar tu factura deberás tener a la mano por lo menos un ticket que hayas recibido en unas unidades médicas",
          heading: "Solicitud de facturas",
          to: "/facturacion",
          subservicios: [
            {
              title: "Solicita tu factura",
              to: "/facturacion/solicitar",
              description:
                "'Para poder solicitar tu factura deberás tener a la mano por lo menos un ticket que hayas recibido en unas unidades médicas.'",
            },
            {
              title: "Consulta tus facturas solicitadas",
              to: "/facturacion/solicitadas",
              description:
                "'Aquí podrás encontrar todas las facturas que hayas solicitado.Recuerda que no las generamos al instante y puede tardemos un poco en tenerla lista.'",
            },
          ],
        },

        {
          title: "Ambulancias",
          description:
            "Aquí podrás realizar la solicitud de ambulancias según tus necesidades.",
          heading: "Solicitud de servicios de ambulacias",
          to: "/ambulancia",
          subservicios: [
            {
              title: "Solicita tu servicio de ambulancia",
              to: "/ambulancia",
              description:
                "Para poder solicitar tu factura deberás tener a la mano por lo menos un ticket que hayas recibido en unas unidades médicas.",
            },
            {
              title: "Consulta tus servicios de ambulancias solicitadas",
              to: "/ambulancias",
              description:
                "Aquí podrás encontrar todas las facturas que hayas solicitado.Recuerda que no las generamos al instante y puede tardemos un poco en tenerla lista.",
            },
          ],
        },
      ],
    };
  },
  methods: {
    _hasSession() {},
  },
  created() {
    this.sesion = this.$session.exists();
  },
};
</script>