<template>
  <section class="technical-drawing__section">
    <div class="empresas_encabezado__inner">
      <div class="empresas_encabezado__exp">
        <div class="empresas_encabezado__exp-title">Atención a empresas</div>
      </div>
      <div class="empresas_encabezado__content">
        <div class="empresas_encabezado__text">
          <div class="membership-description">
            <p class="item-excerpt">
              Para poder acceder y consultar todos nuestros servicios es necesario te registres o inicies sesión.
            </p>
            <div class="mt14">
              <router-link
                to="/empresas/login"
                class="th-hero-slider__btn btn btn--technical"
                v-text="'Iniciar sesión'"
              /><br>
              <router-link
                style="margin-top:24px"
                to="/empresas/registro"
                class="th-hero-slider__btn btn btn--technical no-outlined"
                v-text="'Registrarme'"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="technical-drawing__section empresas_login"></div>
  </section>
</template>
<script>
export default {
  name: "EmpresasHomeNoLogin",
};
</script>