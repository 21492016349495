<template>
  <section class="mt24 mb24 empresas__inner container">
    <header class="empresas__header">
      <h2 class="empresas_home__heading heading">Atención a empresas</h2>
    </header>
    <ul class="empresas_home__list">
      <v-row>
        <v-col
          cols="12"
          md="6"
          id="facturacion"
        >
          <li class="empresas_home__item facturacion">
            <div class="
                empresas_home__item-icon empresas_home__item-icon--interior
              ">
              <svg
                width="49"
                height="49"
                viewBox="0 0 24 24"
              >
                <path d="M8 16h8v2H8zm0-4h8v2H8zm6-10H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11z" />
              </svg>
            </div>
            <h3 class="empresas_home__item-heading">Facturación</h3>
            <div class="empresas_home__item-text">
              Para poder solicitar tu factura deberás tener a la mano por lo
              menos un ticket que hayas recibido al ser atendido en Cruz Roja Mexicana Chihuahua
            </div>
            <v-btn
              depressed
              class="mt16"
              color="primary"
              to="/empresas/factura"
            >
              Solicita tu factura
              <v-icon>chevron_right</v-icon>
            </v-btn><br />
            <v-btn
              outlined
              class="mt16"
              to="/empresas/facturas"
            >
              Consulta tus facturas
              <v-icon>chevron_right</v-icon>
            </v-btn>
          </li>
        </v-col>
        <v-col
          cols="12"
          md="6"
          id="ambulancias"
        >
          <li class="empresas_home__item ambulancias">
            <div class="empresas_home__item-icon empresas_home__item-icon--design">
              <svg
                width="49"
                height="49"
                viewBox="0 0 24 24"
              >
                <path d="M18 18.5a1.5 1.5 0 0 0 1.5-1.5a1.5 1.5 0 0 0-1.5-1.5a1.5 1.5 0 0 0-1.5 1.5a1.5 1.5 0 0 0 1.5 1.5m1.5-9H17V12h4.46L19.5 9.5M6 18.5A1.5 1.5 0 0 0 7.5 17A1.5 1.5 0 0 0 6 15.5A1.5 1.5 0 0 0 4.5 17A1.5 1.5 0 0 0 6 18.5M20 8l3 4v5h-2a3 3 0 0 1-3 3a3 3 0 0 1-3-3H9a3 3 0 0 1-3 3a3 3 0 0 1-3-3H1V6c0-1.11.89-2 2-2h14v4h3M8 6v3H5v2h3v3h2v-3h3V9h-3V6H8Z" />
              </svg>
            </div>
            <h3 class="empresas_home__item-heading">Ambulancias</h3>
            <div class="empresas_home__item-text">
              Aquí podrás reservar los servicios de ambulancias que requieras,
              así como consultar el estatus de tus reservaciones.
            </div>
            <v-btn
              depressed
              class="mt16"
              color="primary"
              to="/empresas/ambulancia"
            >
              Reservar una ambulancia
              <v-icon>chevron_right</v-icon>
            </v-btn><br />
            <v-btn
              outlined
              class="mt16"
              to="/empresas/ambulancias"
            >
              Consulta tus reservaciones
              <v-icon>chevron_right</v-icon>
            </v-btn>
          </li>
        </v-col>
        <v-col
          v-if="esProveedor"
          cols="12"
          md="6"
          id="proveedor"
        >
          <li class="empresas_home__item beneficiarios">
            <div class="
                empresas_home__item-icon empresas_home__item-icon--furniture
              ">
              <svg
                width="49"
                height="49"
                viewBox="0 0 24 24"
              >
                <path d="M21.9,8.89l-1.05-4.37c-0.22-0.9-1-1.52-1.91-1.52H5.05C4.15,3,3.36,3.63,3.15,4.52L2.1,8.89 c-0.24,1.02-0.02,2.06,0.62,2.88C2.8,11.88,2.91,11.96,3,12.06V19c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-6.94 c0.09-0.09,0.2-0.18,0.28-0.28C21.92,10.96,22.15,9.91,21.9,8.89z M18.91,4.99l1.05,4.37c0.1,0.42,0.01,0.84-0.25,1.17 C19.57,10.71,19.27,11,18.77,11c-0.61,0-1.14-0.49-1.21-1.14L16.98,5L18.91,4.99z M13,5h1.96l0.54,4.52 c0.05,0.39-0.07,0.78-0.33,1.07C14.95,10.85,14.63,11,14.22,11C13.55,11,13,10.41,13,9.69V5z M8.49,9.52L9.04,5H11v4.69 C11,10.41,10.45,11,9.71,11c-0.34,0-0.65-0.15-0.89-0.41C8.57,10.3,8.45,9.91,8.49,9.52z M4.04,9.36L5.05,5h1.97L6.44,9.86 C6.36,10.51,5.84,11,5.23,11c-0.49,0-0.8-0.29-0.93-0.47C4.03,10.21,3.94,9.78,4.04,9.36z M5,19v-6.03C5.08,12.98,5.15,13,5.23,13 c0.87,0,1.66-0.36,2.24-0.95c0.6,0.6,1.4,0.95,2.31,0.95c0.87,0,1.65-0.36,2.23-0.93c0.59,0.57,1.39,0.93,2.29,0.93 c0.84,0,1.64-0.35,2.24-0.95c0.58,0.59,1.37,0.95,2.24,0.95c0.08,0,0.15-0.02,0.23-0.03V19H5z" />
              </svg>
            </div>
            <h3 class="empresas_home__item-heading">Proveedores</h3>
            <div class="empresas_home__item-text">
              Como proveedor podrás consultar tus ordenes de compra y sus estatus correspondientes, así como mantener al día la información solicitada.
            </div>
            <v-btn
              depressed
              class="mt16"
              color="primary"
              to="/empresas/ordenesdecompra"
            >
              Consulta tus ordenes de compra
              <v-icon>chevron_right</v-icon>
            </v-btn>
            <br>
            <v-btn
              outlined
              class="mt16"
              to="/empresas/proveedor"
            >
              Tu información
              <v-icon>chevron_right</v-icon>
            </v-btn>
          </li>
        </v-col>
        <v-col
          v-if="puedeTenerBeneficiarios"
          cols="12"
          md="6"
          id="beneficiarios"
        >
          <li class="empresas_home__item beneficiarios">
            <div class="
                empresas_home__item-icon empresas_home__item-icon--furniture
              ">
              <svg
                width="49"
                height="49"
                viewBox="0 0 24 24"
              >
                <path d="M4,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2C2,12.1,2.9,13,4,13z M5.13,14.1C4.76,14.04,4.39,14,4,14 c-0.99,0-1.93,0.21-2.78,0.58C0.48,14.9,0,15.62,0,16.43V18l4.5,0v-1.61C4.5,15.56,4.73,14.78,5.13,14.1z M20,13c1.1,0,2-0.9,2-2 c0-1.1-0.9-2-2-2s-2,0.9-2,2C18,12.1,18.9,13,20,13z M24,16.43c0-0.81-0.48-1.53-1.22-1.85C21.93,14.21,20.99,14,20,14 c-0.39,0-0.76,0.04-1.13,0.1c0.4,0.68,0.63,1.46,0.63,2.29V18l4.5,0V16.43z M16.24,13.65c-1.17-0.52-2.61-0.9-4.24-0.9 c-1.63,0-3.07,0.39-4.24,0.9C6.68,14.13,6,15.21,6,16.39V18h12v-1.61C18,15.21,17.32,14.13,16.24,13.65z M8.07,16 c0.09-0.23,0.13-0.39,0.91-0.69c0.97-0.38,1.99-0.56,3.02-0.56s2.05,0.18,3.02,0.56c0.77,0.3,0.81,0.46,0.91,0.69H8.07z M12,8 c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1S11.45,8,12,8 M12,6c-1.66,0-3,1.34-3,3c0,1.66,1.34,3,3,3s3-1.34,3-3 C15,7.34,13.66,6,12,6L12,6z" />
              </svg>
            </div>
            <h3 class="empresas_home__item-heading">Beneficiarios</h3>
            <div class="empresas_home__item-text">
              Cómo empresa donadora tus empleados cuentan con beneficios en algunos
              servicios en nuestras unidades médicas, registra aquí a tus
              trabajadores.
            </div>
            <v-btn
              depressed
              class="mt16"
              color="primary"
              to="/empresas/beneficiario"
            >
              Registra tus beneficiarios
              <v-icon>chevron_right</v-icon>
            </v-btn><br />
            <v-btn
              outlined
              class="mt16"
              to="/empresas/beneficiarios"
            >
              Consulta tus beneficiarios
              <v-icon>chevron_right</v-icon>
            </v-btn>
          </li>
        </v-col>

        <v-col
          cols="12"
          md="6"
          id="quote"
        >
          <li class="empresas_home__item empresas_home__item--quote quote">
            <blockquote class="empresas_home__quote">
              “Seamos todos hermanos”
            </blockquote>
            <div class="empresas_home__sign">
              <img
                src="@/assets/firma-henry-dunant.png"
                width="300"
                alt="signature Carlos Jr."
              />
            </div>
            <cite class="empresas_home__cite">Henry Dunant<br />
              <span>Fundador de la Cruz Roja</span>
            </cite>
          </li>
        </v-col>
      </v-row>
    </ul>
  </section>
</template>
<script>
export default {
  name: "EmpresasHomeSeleccionar",

  data() {
    return {
      puedeTenerBeneficiarios: false,
      esProveedor: true,
    };
  },
  methods: {
    async _checkIfAviableForBeneficiarios() {
      try {
        const { status, body } = await this.$http.get(
          "beneficiarios/disponibilidad",
          this.$headers
        );
        if (status === 200 && "puede" in body) {
          this.puedeTenerBeneficiarios = body.puede === "si" ? true : false;
        } else {
          this.puedeTenerBeneficiarios = false;
        }
      } catch (err) {
        this.puedeTenerBeneficiarios = false;
      }
    },
  },
  created() {
    this._checkIfAviableForBeneficiarios();
    this.esProveedor = this.$session.has("proveedor");
  },
};
</script>